.page .slick-slide {
    padding: 0 5px;
  }
  
  .page .slick-list {
    margin: 0 2em;
  }

  .page p {
    margin-top: 15px;
    line-height: 38px;
    text-align: justify;
  }

  .page li {
    line-height: 33px;
    text-align: justify;
  }

  .page h5, h4{
    color: #576570;
    font-size: 25px;
    font-weight: 500;
  }

  .page img {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .page h5::before{
    content: "";
    position: absolute;
    width: 35px;
    height: 3px;
    background-color: #00963f;
    bottom: 0;
    left: 0;
  }
  .page .download{
    background: #000;
  }
  .page .objectif {
    background-color: #00963f;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
  }

  .page .mission {
    background-color: #00963f;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
  }

  .page .organetop {
    background-color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    margin-bottom: 5px;
  }
  .page .organebottom {
    background-color: #00963f;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    margin-bottom: 5px;
  }

  .page .organeaside {
    background-color: #edf0f2;
    height: 450px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    margin-bottom: 5px;
  }

  .page .strategie {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    background-color: #efefef;

  }

  .page .valeur {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    background-color: #efefef;

  }

  .card-header{
    background-color: #949ca3 !important;
  }

 .organeaside li, .organeaside h5{
    color:#28a745  !important
  }
 
  
  .objectif p, .mission p, .organebottom p, .organebottom li, .objectif li, .objectif h5, .organebottom h5{
    color:#FFF  !important
  }


  .objectif h5::before, .organebottom h5::before{
    content: "";
    position: absolute;
    width: 35px;
    height: 3px;
    background-color: #ffc107;
    bottom: 0;
    left: 0;
    
  }

  .page h4::before{
    content: "";
    position: absolute;
    width: 35px;
    height: 3px;
    background-color: #00963f;
    bottom: 0;
    left: 0;
  }

  .projet{
  }
  .projet div{
    margin-top: 20px;
    border-right:1px solid#eaeaea;
    border-bottom:1px solid#eaeaea;
    padding: 15px;

  }
