.acces-rapide a:hover{
  text-decoration: underline;
    box-decoration-break: clone;
  }

  .acces-rapide {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-top: 5px;
  }

  .ministere {
    /* padding-left: 400px; */
    /* text-align: right; */
    margin-left: auto;
    margin-right: auto;
  }

  .size-text {
    font-size: 12px;
  }

  .title-acces{
    border-bottom:2px solid white;
  }