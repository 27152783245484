.farmBackground {
    background-image: url("../assets/images/farm.jpg");
}

.farmingBackground {
    background-image: url("../assets/images/Farming.jpg");
}

.africanFarmersBackground {
    background-image: url("../assets/images/African-farmers.jpg");
}

/* .backgroundHomeSlider{
    background-image: url(process.env.REACT_APP_UPLOAD_DIRECTORY) !important;
} */

